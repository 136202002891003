<template>
  <div>
    <base-form
      class="respondentsDetils"
      :componentList="respondentsForm"
      :showBtns="false"
      :formAttrs="{
        model: formData,
      }"
      ref="respondents"
    ></base-form>
  </div>
</template>
<script>
import { respondentsConfig } from '../utils/make-config'
import BaseForm from '@/components/common/base-form/base-form.vue'
export default {
  props: {
    formData: Object
  },
  data () {
    return {
      // respondentsData: {}
    }
  },
  components: { BaseForm },
  computed: {
    respondentsForm () {
      return respondentsConfig()
    },
    respondentsData () {
      return this.formData
    }
  }

}
</script>
<style lang="scss" scoped>
.respondentsDetils {
  padding-left: 20px;
  margin-top: 10px;
}
</style>
